import { useCallback, useContext, useEffect, useRef } from 'react';
import { AngularServicesContext, withAngularServices } from 'react-app';
import { useSelector } from 'react-redux';

import { BrowserRouter, Route, generatePath,
} from 'react-router-dom';
import t from 'react-translate';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { useAppDispatch } from 'redux/store';
import NvRouterSwitch from 'nv-router/nv-switch';
import StateManagerService from 'shared/services/state-manager';

import { unsetAllCachedLecturePages, unsetcurrentLectureAppState } from 'redux/actions/lecture-pages';
import { PreventNavigationReason } from 'redux/schemas/models/lecture-page';
import { LecturePageMode } from '.';
import LecturePageContainer from './lecture-page-container';

export type LecturePageUrlParams = {
  catalogId: string | null,
  mode?: LecturePageMode,
  lecturePageId: number,
  /** The ID of a specific component to scroll to upon page load */
  lectureActivityId?: number,
  // Used in collection lesson when redirecting from linked course lesson page
  fromLinkedCourse?: boolean,
};

/** The url regex to any lecture page that's used to set up routing with react-router.
 * A `linkTolecturePage()` function is defined below to allow dynamically creating urls to the lecture page,
 * used when we are on the page but need to change a param (such as "mode").
 * TODO: Long-term we're going to have to define a list of these strings to serve as the navigable routes
 * in our app and make a more general function than linkToLecturePage() */
export const courseLectureRouteString = '/:catalogId/lecture_pages/:lecturePageId(\\d+)/:mode(edit|reorder)?';

export const collectionLectureRouteString = '/:collectionId/lecture_pages/:lecturePageId(\\d+)/:mode?';

export const linkTolecturePage = (params: LecturePageUrlParams) => {
  let mode = params.mode === LecturePageMode.VIEW ? undefined : params.mode;

  if (mode === LecturePageMode.RESTRICTED_EDIT || mode === LecturePageMode.LINKED_EDIT) {
    mode = LecturePageMode.EDIT;
  }

  let newPath = generatePath(params.catalogId ? courseLectureRouteString : collectionLectureRouteString, {
    ...params,
    // Delete the mode param for 'view' since it should not be present in the url
    mode,
  });

  // Unfortunately, generatePath() does not handle adding query params. We'll have to tweak this for each
  // additional query param we add to the lecture page urls
  if (params.lectureActivityId) {
    newPath += `?lectureActivityId=${params.lectureActivityId}`;
  }

  return newPath;
};

/** Relates the page url to a specific lecture page experience via the react-router-dom library */
export const LecturePageRouter = () => {
  const dispatch = useAppDispatch();
  const { $injector, $scope } = useContext(AngularServicesContext);
  const { preventPageNavigation } = useSelector(state => state.app.lecturePage);

  const confirmationAction = useRef(null);

  useEffect(() => {
    confirmationAction.current = (messages, cb) => openConfirmationDialog({
      title: messages,
      onConfirm: () => cb(true),
      onCancel: () => cb(false),
      confirmText: preventPageNavigation === PreventNavigationReason.SAVING_IN_PROGRESS
        ? t.FORM.SAVING_CHANGES.OK()
        : t.FORM.DISCARD_CHANGES(),
      cancelText: t.NOVOED.CANCEL(),
    });
  }, [preventPageNavigation]);

  /** Flash a confirmation dialog prompting to the user to confirm out navigation. This is enabled via the <Prompt> in lecture-page-containter.tsx  */
  const getUserConfirmation = useCallback((messages, cb) => {
    if (confirmationAction.current) {
      dispatch(confirmationAction.current(messages, cb));
    }
  }, [dispatch]);

  const deregisterStateChangeStartRef = useRef(null);
  // Prevent out navigation when data is being saved
  // TODO: This needs to be pulled out into a hook for other projects. But, let's
  // consider upgrading to react-router v6 (now in beta) at the time of, as it has a
  // useBlock hook that may simplify this along with the <Prompt>
  //
  // NOTE: You *must* do this outside of any components managed by the React Router. Putting this useEffect inside
  // on of thoe components (like LecturePageContainer) causes strange race conditions w/ the angular-ui-router, partly because
  // of how react-router prevents the nav by doing a nav back to the current state
  useEffect(() => {
    const StateManager = $injector.get('StateManager') as ReturnType<typeof StateManagerService>;

    deregisterStateChangeStartRef.current = StateManager.registerStateChangeStart(
      () => {
        // Prevent showing the confirm dialog when navigating to another lecture page state, because
        // this is handled separately in the React code vi react-router
        // Failing to avoid this will cause duplicate overlays
        const lastState = StateManager.lastStateAttempted ?? StateManager.lastStateEntered;
        if (lastState.name.indexOf('lecture-page') !== -1) {
          return false;
        }
        return !!preventPageNavigation;
      },
      preventPageNavigation === PreventNavigationReason.SAVING_IN_PROGRESS
        ? 'shared/templates/modal-saving-navigate-away.html'
        : 'shared/templates/modal-navigate-away-unsaved-changes.html',
      `${preventPageNavigation === PreventNavigationReason.SAVING_IN_PROGRESS
        ? t.FORM.SAVING_CHANGES.NAVIGATE_AWAY()
        : t.FORM.UNSAVED_CHANGES.NAVIGATE_AWAY_CHANGES()
      }`,
    );

    const { current: deregister } = deregisterStateChangeStartRef;

    return () => {
      deregister();
    };
  }, [$injector, preventPageNavigation]);

  // Clear the caching of all viewed lecture pages on exit of this component
  // so that lecture page will be called again on the next visit to lecture
  // page experience
  useEffect(() => () => {
    dispatch(unsetAllCachedLecturePages());
    dispatch(unsetcurrentLectureAppState());
  }, [dispatch]);

  return (
    <BrowserRouter
      basename='/#!/courses'
      getUserConfirmation={getUserConfirmation}
    >
      <NvRouterSwitch>
        <Route path={courseLectureRouteString} strict={false}>
          <LecturePageContainer />
        </Route>
      </NvRouterSwitch>
    </BrowserRouter>
  );
};


export default withAngularServices(LecturePageRouter);
