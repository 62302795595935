import store from 'redux/store';
import { resetTimelineCachedCourseLookUp } from 'redux/actions/timeline';
import { isHandheld } from 'styles/global_defaults/media-queries';
import CourseHomeTimelineContent from '../components/course_home/course-home-timeline-content';

/* @ngInject */
export default function TimelineShowController(
  StateManager,
  CurrentUserManager,
  CurrentCourseManager,
  CourseHomeManager,
  TimelinesManager,
  ConfirmationOverlays,
  AlertMessages,
  $scope,
  $state,
  $stateParams,
  $timeout,
  RailsRoutes,
  InteroperabilityRoutes,
  $q,
  $uibModal,
  _,
  nvCurrentPage,
  ConfettiAnimation,
  config,
  humps,
  PusherManager,
) {
  const vm = this;

  let getAdminCreditsPromise = null;
  let getCompletionCriteriaPromise = null;
  let getTimelinePromise = null;

  // To compare with currentUserCourse.completionStatus to check completion
  vm.COMPLETIONSTATUS = {
    AUTO: 1,
    MANUAL: 2,
  };

  vm.PRESET = {
    SECTION_SUBSECTION_LESSON: 'three_layer',
    SUBSECTION_LESSON: 'two_layer_collapse',
    SECTION_LESSON: 'two_layer_expand',
  };

  vm.config = config;
  vm.manager = TimelinesManager;
  vm.CourseHomeManager = CourseHomeManager;
  vm.TimelinesManager = TimelinesManager;
  vm.RailsRoutes = RailsRoutes;
  vm.InteroperabilityRoutes = InteroperabilityRoutes;
  vm.CurrentUserManager = CurrentUserManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.$stateParams = $stateParams;
  vm.nvCurrentPage = nvCurrentPage;
  vm.currentUserCourse = vm.CurrentUserManager.courseIdToUserCourseHash[vm.CurrentCourseManager.course.id];
  vm.isHandheld = isHandheld;

  $scope.CourseHomeTimeline = CourseHomeTimelineContent;

  vm.manager.selectedTab = (vm.manager.selectedTodo && vm.manager.isViewMode()) ? 'Todos' : 'Outline'; // this is only on the manager because the lightbox header and main need to be able to talk to each other

  vm.addContentPopoverIsOpen = false;
  vm.refetchBannerShown = false;

  if (CurrentUserManager.isAdmin()) {
    initialize();
  }

  function initialize() {
    PusherManager.courseChannel(vm.CurrentCourseManager.course.id).bind('lecture_page_link_completed', linkingCompletionHandler);
    PusherManager.courseChannel(vm.CurrentCourseManager.course.id).bind('lecture_page_sync_completed', linkingCompletionHandler);
    PusherManager.courseChannel(vm.CurrentCourseManager.course.id).bind('lecture_page_convert_completed', linkingCompletionHandler);
  }

  function linkingCompletionHandler(pusherData) {
    pusherData = humps.camelizeKeys(pusherData);

    // Updating the lecture page details after completing the linking
    if (vm.manager.currentTimeline) {
      vm.manager.updateLecturePage(pusherData.lecturePageId);
    }
  }

  /** This is a shim to make the new React lecture page arch drive this timelines controller into
   * scrolling to the correct lecture page when navigating from a lecture page to the course home page.
   *
   * The Angularjs app was written to expect that the current lecture page would be recorded in the TimelinesManager
   * when navigating out of the lecture page, which is then used to fire the scrollTo & section expansion behavior.
   * Updating the current lecture page may avoid other incompatibility issues as well
   * */
  $scope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
    // Due the existence of both 'lecture-page' and 'lecture-page-slash' states, it's more convenient
    // to just check the parent state name here
    if (fromState?.parent === 'lecture-page-parent') {
      vm.TimelinesManager.setCurrentLecturePage(fromParams.id);
    }

    // Resetting the timeline cache when visiting the course home edit.
    // Course home doesn't have connection
    if (toState.name === 'course-home-edit') {
      store.dispatch(resetTimelineCachedCourseLookUp($stateParams.catalogId));
    }
  });

  vm.selectTab = function (tab) {
    if (tab !== 'Todos') {
      vm.manager.selectedTodo = null;
    }
    vm.manager.selectedTab = tab;
  };

  vm.shouldShowPlaceholder = () => {
    if (vm.CurrentCourseManager.course.inArchiveMode) {
      return false;
    }

    if (!vm.manager.currentTimeline) {
      return true;
    }

    if (vm.manager.currentTimeline?.hasLectureComponents) {
      return false;
    }

    if (vm.manager.selectedTab !== 'Outline' || vm.manager.isEditMode() || vm.manager.isReorderMode()) {
      return true;
    }

    return false;
  };

  vm.scrollToTop = function () {
    $timeout(() => {
      $('#main-panel').scrollToElementAnimated($('.timeline-section')[0]);
    });
  };

  vm.getStatementUrl = (catalogId, userId) => $state.href('statement-modal', { catalogId, userId });

  vm.getTimeline = (forceLoad = false) => {
    if ($state.is('program-timelines')) {
      getTimelinePromise = vm.manager.getTimeline($stateParams.catalogId, CurrentUserManager.user.id, CurrentUserManager.isAdmin(), CurrentCourseManager.course, { forceLoad, editMode: vm.manager.isEditMode() });
      vm.manager.getAdminCredits($stateParams.catalogId, CurrentUserManager.user.id);
    } else {
      if ($state.is('course-home-edit') && !CurrentUserManager.isAdmin()) {
        $state.go('course-home', { catalogId: vm.$stateParams.catalogId });
      }
      getTimelinePromise = vm.manager.getTimeline($stateParams.catalogId, CurrentUserManager.user.id, CurrentUserManager.isAdmin(), CurrentCourseManager.course, { forceLoad, editMode: vm.manager.isEditMode() });
      getAdminCreditsPromise = vm.manager.getAdminCredits($stateParams.catalogId, CurrentUserManager.user.id);

      if (!CurrentCourseManager.course.completionCriteria) {
        getCompletionCriteriaPromise = CurrentCourseManager.course.loadCourseCompletionCriteria();
      }

      $q.all([getTimelinePromise.fullTimelinePromise, getAdminCreditsPromise, getCompletionCriteriaPromise]).then(() => {
        vm.completionCriteria = {
          pointsValue: vm.manager.currentTimeline?.pointsReceived + vm.manager.totalCreditsReceived,
          assignmentsValue: vm.manager.currentTimeline?.requiredAssignmentsCompleted?.length,
          todosValue: vm.manager.currentTimeline?.requiredTodosCompleted?.length,
          // Grab next three from CurrentCourseManager
          pointsMax: vm.CurrentCourseManager.course.requiredPoints(),
          assignmentsMax: vm.CurrentCourseManager.course.requiredAssignmentsCount(),
          todosMax: vm.CurrentCourseManager.course.requiredTodosCount(),
        };

        vm.isCompleted = {
          points: vm.completionCriteria.pointsValue >= vm.completionCriteria.pointsMax,
          assignments: vm.completionCriteria.assignmentsValue >= vm.completionCriteria.assignmentsMax,
          todos: vm.completionCriteria.todosValue >= vm.completionCriteria.todosMax,
        };

        /**
         * These extra fields are not in the currentUserCourse object but this is
         * used in some areas. So added those fields from CurrentCourseManager.
         */
        const extraUserCourseAttr = _.pick(
          vm.CurrentCourseManager.course.userCourse,
          'catalogId', 'mentors', 'courseLongTeam',
        );
        const userCourse = _.extend(vm.currentUserCourse, extraUserCourseAttr);

        vm.CurrentCourseManager.setUserCourse(userCourse);

        vm.requirementsCompleted = vm.CurrentCourseManager.course.getCompletionCriteriaCount()
          && vm.isCompleted.points && vm.isCompleted.assignments && vm.isCompleted.todos;

        if (vm.currentUserCourse.completionStatus || (vm.CourseHomeManager.courseHome?.showStatementOfAccomplishment)
          || (vm.requirementsCompleted && vm.CurrentCourseManager.course.completionCriteria.automatedCompletionsEnabled)) {
          ConfettiAnimation.showConfetti();
        }
      });
    }

    getTimelinePromise.fullTimelinePromise.then();
  };

  // Watch the points and todos change from TimelinesManager
  // Currently this is applicable only for practice feedback,
  // when accessed from practice room
  $scope.$watchCollection(() => [
    vm.TimelinesManager?.currentTimeline?.pointsReceived,
    vm.TimelinesManager?.currentTimeline?.requiredTodosCompleted?.length,
  ], (newValues, oldValues) => {
    if (newValues !== oldValues && vm.completionCriteria) {
      vm.completionCriteria.pointsValue = vm.TimelinesManager.currentTimeline?.pointsReceived + vm.TimelinesManager.totalCreditsReceived;
      vm.completionCriteria.todosValue = vm.TimelinesManager.currentTimeline?.requiredTodosCompleted.length;
      vm.isCompleted.points = vm.completionCriteria.pointsValue >= vm.completionCriteria.pointsMax;
      vm.isCompleted.todos = vm.completionCriteria.todosValue >= vm.completionCriteria.todosMax;
    }
  });

  vm.showPresetModal = function () {
    vm.addContentPopoverIsOpen = false;

    $uibModal.open({
      templateUrl: 'timelines/templates/create-from-preset-modal.html',
      controller: 'createFromPresetCtrl as vm',
      windowClass: 'full-screen-modal-handheld full-screen-modal-tablet create-from-preset-modal nv-course-timeline',
    });
  };

  vm.toggleCardView = function () {
    if (!vm.CurrentCourseManager.course.cardView) {
      ConfirmationOverlays.openConfirmationModal(
        'timelines/templates/card-view-confirmation-overlay.html',
        'AttachModalResolvesToVmCtrl',
        {
          vmResolves() {
            return {
              CurrentCourseManager,
              TimelinesManager,
            };
          },
        },
      ).result.then(() => {
        vm.TimelinesManager.toggleCardView($stateParams.catalogId);
      });
    } else {
      vm.TimelinesManager.toggleCardView($stateParams.catalogId);
    }
  };

  vm.shouldShowCompletionCriteria = function () {
    const timelineCompletionCriteria = vm.completionCriteria;
    const courseCompletionCriteria = vm.CurrentCourseManager.course.completionCriteria;

    if (vm.CurrentCourseManager.course.inArchiveMode) {
      return true;
    } if (!vm.manager.isViewMode() || !timelineCompletionCriteria || !courseCompletionCriteria) {
      // Hide the criteria UI if we're in an edit mode or if the criteria data has not finished loading
      return false;
    }

    // Do any lecture pages or lecture subsections exist?
    const hasPages = vm.manager.currentTimeline && (
      !_.isEmpty(vm.manager.currentTimeline.lecturePages)
      || !_.isEmpty(vm.manager.currentTimeline.lectureSubsections)
    );

    // Does any points, assignments, or todos max exist?
    const hasMax = timelineCompletionCriteria.pointsMax || timelineCompletionCriteria.assignmentsMax || timelineCompletionCriteria.todosMax;

    // Do we have a max while automated completions are enabled?
    const automatedCompletionsWithMax = courseCompletionCriteria.automatedCompletionsEnabled
      && hasMax;

    return hasPages && ((vm.currentUserCourse?.completionStatus) || automatedCompletionsWithMax);
  };


  vm.hasUnsavedChanges = function () {
    return vm.manager.isReorderMode() && vm.manager.currentTimeline.reorderDirty;
  };

  // warn if leaving the page and have unsaved changes
  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(
    () => vm.hasUnsavedChanges(),
    'shared/templates/modal-navigate-away.html',
    'FORM.NAVIGATE_AWAY.CLOSE_WINDOW',
  );

  $scope.$on('$destroy', () => {
    if (CurrentUserManager.isAdmin()) {
      const courseId = vm.CurrentCourseManager.course?.id
        ? vm.CurrentCourseManager.course.id
        : CurrentUserManager.coursesHashByCatalogId[vm.CurrentCourseManager.currentCatalogId].id;

      PusherManager.courseChannel(courseId).unbind('lecture_page_link_completed', linkingCompletionHandler);
      PusherManager.courseChannel(courseId).unbind('lecture_page_sync_completed', linkingCompletionHandler);
      PusherManager.courseChannel(courseId).unbind('lecture_page_convert_completed', linkingCompletionHandler);
    }

    vm.deregisterStateChangeStart?.();
  });

  if (!vm.manager.isViewMode()) {
    vm.getTimeline();
  }
}
